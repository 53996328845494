import { useState } from 'react';
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ErrorTexts, GeneralTexts, LoginTexts } from '../../language/Texts';
import { HttpRequest } from '../../utils/HttpRequest';
import './ConfirmDialog.scss';

function ConfirmDialog({ isModalOpen, setIsModalOpen }) {
    const [password, setPassword] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [unexpectedError, setUnexpectedError] = useState(false)

    const loginData = { username: sessionStorage.getItem('username') as string, password: password }

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await new HttpRequest().login(loginData)
            successHandling()
        } catch (error) {
            console.log(error)
            errorHandling(error)
        }
    };

    const successHandling = () => {
        sessionStorage.setItem('isLoggedIn', 'true')
        setIsModalOpen(false)
    }

    const errorHandling = (error) => {
        setShowAlert(true)
        setPassword('')
        error?.response?.status ? setUnexpectedError(false) : setUnexpectedError(true)
        setTimeout(() => {
            setShowAlert(false)
        }, 5000)
    }

    return (
        <>
            <Modal show={isModalOpen} className="session_dialog">
                <Modal.Header className='session_modal_header' >{GeneralTexts.newLogin}</Modal.Header>
                <Modal.Body className='session_modal_title' > {GeneralTexts.sessionInfo}</Modal.Body>
                <Alert className="alert" variant="warning" show={showAlert} >
                    {unexpectedError ? ErrorTexts.unexpectedError : ErrorTexts.wrongPasswort}
                </Alert>
                <form onSubmit={handleSubmit}>
                    <Modal.Body className='session_modal_title' >
                        <input
                            onInvalid={e => (e.target as HTMLInputElement).setCustomValidity(GeneralTexts.customWarning)}
                            onInput={e => (e.target as HTMLInputElement).setCustomValidity('')}
                            required={true}
                            type="password"
                            value={password}
                            placeholder='Passwort *'
                            onChange={(e) => setPassword(e.target.value)}
                            className="session_input"
                            data-testid="password-input"
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className='session_modal_button' variant="primary" type='submit'>
                            {LoginTexts.signIn}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}
export default ConfirmDialog