import { ErrorTexts } from '../../language/Texts'
import "./UnexpectedError.scss"
function UnexpectedError() {
    return (
        <div className="unexpected_wrapper">
            <p>Ooops! {ErrorTexts.sthWrong}</p>
        </div>
    )
}

export default UnexpectedError
