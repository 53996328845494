import Rive, { useRive } from '@rive-app/react-canvas';
import { useEffect, useState } from 'react';
import check from "../../assets/check.riv";
import './RiveComponent.scss'

export default function RiveContainer() {
    const [isPlaying, setIsPlaying] = useState(true);

    const { RiveComponent } = useRive({
        src: check,
        automaticallyHandleEvents: isPlaying,
        autoplay: isPlaying,
    });

    // animation plays 1.7 seconds after that it should be stopped
    useEffect(() => {
        setTimeout(() => {
            setIsPlaying(false);
        }, 1700)
    }, [isPlaying]);

    return (
        <div data-testid='success_animation' className='success_animation'>
            {isPlaying
                ? <RiveComponent />
                : <Rive
                    src={check}
                    className='rive_container'
                    stateMachines={'auto'}
                />}
        </div>

    );
}