import { EventsType } from "react-idle-timer/dist/types/EventsType";

export const IdleTimerEvents: EventsType[] = [
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange'
]