import redCross from "../../../assets/red.jpg";
import { ErrorTexts, GeneralTexts } from "../../../language/Texts";
import { GeneralAttributes } from '../../../models/GeneralAttributes';
import { INetilionResponse } from '../../../models/INetilionResponse';
import Loading from '../../Loading/Loading';
import RiveContainer from "../../riveComponent/RiveComponent";
import "./NetilionResponse.scss";

interface NetilionResponseProps {
    netilionResponse: INetilionResponse,
    loading: boolean,
    manufacturer: string
}

const NetilionResponse = ({ netilionResponse, loading, manufacturer }: NetilionResponseProps) => {
    const isLoading = loading && !netilionResponse.serialNumber && manufacturer !== GeneralAttributes.ari && manufacturer !== GeneralAttributes.krohne && manufacturer !== GeneralAttributes.bray
    const successView = () => {
        return (
            <>
                <div className="success_response">
                    <RiveContainer />
                </div>
                <div className="success_response">
                    <p className='result_text'>{netilionResponse.manufacturer}</p>
                    <p className='result_text' data-testid='result_text'>
                        {GeneralTexts.serialNumber}: {netilionResponse?.serialNumber}
                    </p>
                </div >
            </>
        )
    }

    const unsuccessView = () => {
        return (
            <>
                <div className="fail_response">
                    <img className="result_image" src={redCross} alt="unsuccessful" />
                    <p className='result_text'>{netilionResponse?.message ? netilionResponse.message : ErrorTexts.unexpectedError}</p>
                </div>
            </>
        )
    }

    return (
        <>
            {isLoading && <Loading />}
            {netilionResponse?.serialNumber ?
                <div className="response-wrapper">
                    {netilionResponse.isSuccess === true && successView()}
                    {netilionResponse.isSuccess === false && unsuccessView()}
                </div>
                : null}
        </>
    )
}

export default NetilionResponse