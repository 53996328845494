import { BallTriangle } from 'react-loader-spinner'
import "./Loading.scss"

const Loading = () => {
    return (
        <div className="loading-wrapper">
            <BallTriangle color="#5ab6f3" height={60} width={60} />
        </div>
    )
}
export default Loading