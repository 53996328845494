import version from '../version.json'
import translation from '../language/translation.json'

export class Utils {

    static getBrowserLanguage(): string {
        return navigator.language.substring(0, 2)
    }

    static isLanguageSupported(lang: string): boolean {
        return translation.language[lang]
    }

    static arrangeErrorMessages(message: string): string {
        switch (message) {
            case "EH":
                return ''
            case "Ari":
                return ''
            case "Krohne":
                return ''
            default:
                return ''
        }
    }

    static isValidUrl(url: string): boolean {
        const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        return urlPattern.test(url);
    }

    static isNetilionUrlCorrect(url: string, allowedNetilionUrls: string): boolean {
        const netilionUrl = new URL(url)
        let allowedUrls: string[]

        if (allowedNetilionUrls) {
            allowedUrls = allowedNetilionUrls.replaceAll(' ', '').split(',')
        } else if (sessionStorage.getItem('allowedNetilionUrls')) {
            allowedUrls = sessionStorage.getItem('allowedNetilionUrls')!.replaceAll(' ', '').split(',')
        } else {
            allowedUrls = []
        }

        if (netilionUrl.hostname === 'retrofit22.conexo.one' || allowedUrls.includes(netilionUrl.hostname)) {
            return true
        } else {
            return false
        }
    }

    static getOperatingSystemVersion() {
        const userAgent = navigator.userAgent;
        let osVersion = "Unknown";
        if (userAgent.indexOf("Windows NT") !== -1) {
            // For Windows
            const matches = userAgent.match(/Windows NT ([^\s;]+)/);
            if (matches) {
                osVersion = matches[0];
            }
        } else if (userAgent.indexOf("Mac OS X") !== -1) {
            // For macOS
            const matches = userAgent.match(/Mac OS X ([^\s;]+)/);
            if (matches) {
                osVersion = matches[0];
            }
        } else if (userAgent.indexOf("Linux") !== -1) {
            // For Linux
            osVersion = "Linux";
        } else if (userAgent.indexOf("Android") !== -1) {
            // For Android
            const matches = userAgent.match(/Android ([^\s;]+)/);
            if (matches) {
                osVersion = matches[0];
            }
        } else if (userAgent.indexOf("iOS") !== -1) {
            // For iOS
            const matches = userAgent.match(/OS ([^\s;]+)/);
            if (matches) {
                osVersion = matches[0];
            }
        }
        return osVersion;
    }

    static getBrowserName = () => {
        const userAgent = navigator.userAgent;
        const browsers = {
            edge: /edge|edg/i,
            ie: /msie|trident/i,
            opera: /opera|opr/i,
            brave: /brave/i,
            samsung: /samsungbrowser/i,
            uc: /ucbrowser|ucweb/i,
            yandex: /yabrowser/i,
            silk: /silk/i,
            maxthon: /maxthon/i,
            epiphany: /epiphany/i,
            vivaldi: /vivaldi/i,
            sleipnir: /sleipnir/i,
            waterfox: /waterfox/i,
            palemoon: /palemoon/i,
            qutebrowser: /qutebrowser/i,
            coc_coc: /coc_coc_browser/i,
            iridium: /iridium/i,
            safari: /safari/i,
            firefox: /firefox/i,
            chrome: /chrome/i,
            unknown: /./, // Default regex to match any other browser (should be last).
        };

        for (const browser in browsers) {
            if (browsers[browser].test(userAgent)) {
                return browser;
            }
        }
        return 'unknown'; // If the browser is not recognized, return 'unknown'.
    };


    static getSystemInfo() {
        const displayResolution = window.screen.width + ' x ' + window.screen.height
        const osVersion = this.getOperatingSystemVersion()
        const browserLanguage = this.getBrowserLanguage()
        const browser = this.getBrowserName()
        const feVersion = version.version
        return { displayResolution, osVersion, browserLanguage, browser, feVersion }
    }
}