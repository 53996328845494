import redCross from "../../../assets/red.jpg"
import { ErrorTexts, GeneralTexts } from "../../../language/Texts"
import { GeneralAttributes } from "../../../models/GeneralAttributes"
import { INetilionResponse } from "../../../models/INetilionResponse"
import { BackendResult } from "../../../utils/BackendResult"
import Loading from "../../Loading/Loading"
import RiveContainer from "../../riveComponent/RiveComponent"

interface ConexoResponseProps {
    conexoResponse: BackendResult | null
    loading: boolean,
    netilionResponse: INetilionResponse,
    manufacturer: string
}

const ConexoResponse = ({ conexoResponse, loading, netilionResponse, manufacturer }: ConexoResponseProps) => {
    let isLoading = loading && (netilionResponse.serialNumber || manufacturer === GeneralAttributes.ari || manufacturer === GeneralAttributes.krohne || manufacturer === GeneralAttributes.krohne || manufacturer === GeneralAttributes.bray)

    const successView = () => {
        return (
            <>
                <div className="success_response">
                    <RiveContainer />
                </div>
                <div className="success_response">
                    <p className='result_text'>{GeneralTexts.successful}</p>
                </div>
            </>
        )
    }

    const unsuccessView = (conexoResponse: BackendResult) => {
        return (
            <div>
                <h5 className='error_title'>{ErrorTexts.error}</h5>
                <div className="fail_response">
                    <img className="result_image" src={redCross} alt="redCross" />
                    {arrangeErrorMessages(conexoResponse)}
                </div>
            </div>
        )
    }

    const arrangeErrorMessages = (conexoResponse: BackendResult) => {
        if (conexoResponse.error) {
            return <p className='result_text'>{conexoResponse.error}</p>
        } else if (conexoResponse.data?.error) {
            // The error messages from the portal include HTML tags, for example <bold>Digital ID</bold>
            // That's why we use **dangerouslySetInnerHTML**
            return <p className='result_text' dangerouslySetInnerHTML={{ __html: conexoResponse.data.error }} />
        } else {
            return <p className='result_text'> {ErrorTexts.sthWrong}</p>
        }
    }

    return (
        <>
            {isLoading ? <Loading /> : null}
            {conexoResponse ?
                <div className="response-wrapper">
                    {conexoResponse.success ? successView() : unsuccessView(conexoResponse)}
                </div>
                : null}
        </>
    )
}

export default ConexoResponse