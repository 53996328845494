import logo from '../../assets/conexo.png'
import LanguageSelect from '../LanguageSelect/LanguageSelect'
import "./AppHeader.scss"

function AppHeader({ setLanguage, language }) {

    return (
        <div className="header_wrapper">
            <img className="header_logo" src={logo} alt="conexo-logo" />
            <p className='icon_text'>OnlineDataConverter</p>
            <LanguageSelect
                setLanguage={setLanguage}
                language={language} />
            <p className='icon_border'></p>
        </div>
    )
}

export default AppHeader
