export class BackendResult {
    success: boolean
    statusCode?: number | null
    error?: any
    data?: any

    constructor(success: boolean, statusCode?: number | null, error?: any, data?: any) {
        this.success = success
        this.statusCode = statusCode
        this.error = error
        this.data = data
    }
}