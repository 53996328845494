import { BrowserMultiFormatReader } from "@zxing/library";
import classnames from "classnames";
import { useCallback, useEffect } from "react";
import "./BarcodeScanner.scss";

interface IBarcodeScannerProps {
    onClose: boolean,
    setInputValue: (serialNumber: string) => void,
    setOnClose: (onClose: boolean) => void
}
function BarcodeScanner({ onClose, setInputValue, setOnClose }: IBarcodeScannerProps) {

    const onSuccess = (result) => {
        setInputValue(result.text);
        setOnClose(false)
    };

    const codeReader = new BrowserMultiFormatReader();

    const initializeSingleScan = useCallback(async () => {
        try {
            const result = await codeReader.decodeFromInputVideoDevice(undefined, "video");
            codeReader.reset();
            onSuccess(result);
        } catch (error) {
            console.error('error on scanner : ', error);
        }
    }, [onClose, codeReader, onSuccess]);

    useEffect(() => {
        onClose && initializeSingleScan()
        return () => {
            codeReader.reset();
        };
    }, []);

    return (
        <video
            data-testid="barcode_scanner"
            id="video"
            className={classnames("barcodeScanner")}
        />
    );
}

export default BarcodeScanner;