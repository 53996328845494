import { Button, Modal } from 'react-bootstrap';
import BarcodeScanner from '../barcodeScanner/BarcodeScanner';
import './Modal.scss'
import { GeneralTexts } from '../../language/Texts';

interface IModalProps {
    toggleModal: () => void,
    onClose: boolean,
    setInputValue: (serialNumber: string) => void,
    setOnClose: (onClose: boolean) => void
}

const ModalApp = ({ toggleModal, onClose, setInputValue, setOnClose }: IModalProps) => {

    return (
        <Modal
            onHide={toggleModal}
            show={onClose}
            size="lg"
            centered
        >
            <Modal.Body className='modal_body' >
                <BarcodeScanner
                    onClose={onClose}
                    setInputValue={setInputValue}
                    setOnClose={setOnClose} />
                <Button variant="primary" bsPrefix="close-modal" onClick={toggleModal} className="close-modal">
                    {GeneralTexts.closeCamera}
                </Button>
            </Modal.Body>
        </Modal>
    )
}

export default ModalApp
