import { requiredPermissions } from "../models/RequiredPermissions"
import { IPermissionLists } from "../models/IPermissionLists";

export class PermissionCheck {
    static check(permissionLists: IPermissionLists) {
        let requiredImportPermissions = requiredPermissions.importList
        let requiredExportPermissions = requiredPermissions.exportList

        const isContainsAllImport = requiredImportPermissions.every(element => {
            return permissionLists?.importList.includes(element);
        });

        const isContainsAllExport = requiredExportPermissions.every(element => {
            return permissionLists?.exportList.includes(element);
        });

        return isContainsAllImport && isContainsAllExport && permissionLists.success
    }
}