import { ErrorTexts, GeneralTexts } from "../../language/Texts"
import "./NoPermissionInfo.scss"

function NoPermissionInfo() {
    return (
        <div className="permission_wrapper">
            <p>Ooops! {ErrorTexts.sthWrong}</p>
            <p>{GeneralTexts.authorizationWarning}</p>
        </div>
    )
}

export default NoPermissionInfo
