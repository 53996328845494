import SelectSearch from 'react-select-search';
import i18n from '../../language/i18n';
import './LanguageSelect.scss';
import translation from '../../language/translation.json';

function LanguageSelect({ setLanguage, language }) {

    const languages = translation['language'];
    const languageCodes = Object.keys(languages)

    const handleValue = (e) => {
        setLanguage(e)
        i18n.changeLanguage(e)
    }

    let options: { name: string, value: string }[] = []

    languageCodes.forEach((code, index) => {
        options.push({ name: code.toLocaleUpperCase(), value: code })
    })

    return (
        <div className='language_select'>
            <SelectSearch
                disabled={false}
                options={options}
                value={language}
                onChange={(e) => handleValue(e)}
            />
        </div>
    )
}

export default LanguageSelect