import { createBrowserHistory } from "history";
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import App from './App';
import reportWebVitals from './extensions/reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss'

let history = createBrowserHistory()

ReactDOM.render(
    <Router history={history}>
        <App />
    </Router>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();